<template>
  <touch-card
    :disable="true"
    class="pb-50"
  >
    <b-row class="pb-0 d-flex">
      <b-col
        v-if="emoji !== null"
        class="card-icon xl"
      >
        {{ emoji }}
      </b-col>
      <b-col
        class="d-flex justify-content-between flex-column w-100"
        :class="{'ml-50' : emoji !== null}"
      >
        <div class="d-flex justify-content-between">
          <div class="flex-fill">
            <h6
              v-if="subCard"
              class="mb-25"
            >
              {{ name }}
            </h6>
            <h4
              v-else
              class="mb-25 card-title"
            >
              {{ name }}
            </h4>
            <div>
              <span :class="'text-'+variant">{{ numberFormat(totalWeight) }} {{ $t('units.kg') }}</span>
              <span
                class="text-black-50 font-weight-light"
              > / </span>
              <span :class="'text-'+variant">{{ numberFormat(quantity) }} {{ $t('units.package') }}</span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="pt-1 mt-2 pb-0 d-flex">
      <b-col>
        <div
          v-for="(item, key) in staffMembersHandler"
          :key="item.id"
          class="pb-25"
          :class="{'mb-2':staffMembersHandler.length>key+1}"
        >
          <b-card-text
            class="mb-50 d-flex flex-row align-items-center"
            style="line-height: 1.3em;"
          >
            <span
              v-if="displayLanguage"
              :class="{'mr-1':typeof item.language !== 'undefined'}"
            >{{ (typeof item.language !== 'undefined' ? flags[item.language] : '') }}</span>
            <div>
              <strong class="d-block">
                {{ item.name }}
                <span class="text-black-75">{{
                  (typeof item.code !== 'undefined' && item.name !== item.code ? '(N°' + item.code + ')' : '')
                }}</span>
              </strong>
              <span :class="'text-'+variant">{{ numberFormat(item.total_weight) }} {{ $t('units.kg') }}</span> /
              <span :class="'text-'+variant">{{ numberFormat(item.quantity) }} {{ $t('units.package') }}</span>
            </div>
          </b-card-text>

          <b-progress
            :style="typeof item.language !== 'undefined' && displayLanguage ? 'margin-left: 33px;' :''"
            :value="item.total_weight"
            :max="totalWeight"
            height="12px"
            :variant="variant"
            :animated="animated"
          />
        </div>

        <div v-if="staffMembersHandler.length === 0">
          <p class="text-muted text-center mb-0">
            {{ $t('therms.no-data-to-display') }}
          </p>
        </div>
      </b-col>
    </b-row>
  </touch-card>
</template>

<script>
import TouchCard from '@core/components/touch-card/TouchCard.vue'
import {
  BCardText, BCol, BProgress, BRow,
} from 'bootstrap-vue'
import { sortPerCode, sortPerQuantity, sortPerWeight } from '@/declations/PickingResumeSort'
import { sort } from 'fast-sort'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCol,
    BRow,
    TouchCard,
    BProgress,
    BCardText,
  },
  props: {
    id: {
      type: [Number],
      required: true,
    },
    subCard: {
      type: [Boolean],
      default: false,
    },
    emoji: {
      type: [String, null],
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    totalWeight: {
      type: [Number, String],
      required: true,
    },
    quantity: {
      type: [Number, String],
      required: true,
    },
    items: {
      type: [Object, Array],
      required: true,
    },
    variant: {
      type: [String, undefined],
      default: undefined,
    },
    animated: {
      type: [Boolean, undefined],
      default: undefined,
    },
    filterActive: {
      type: Boolean,
      default: true,
    },
    sortBy: {
      type: String,
      default: sortPerWeight,
    },
  },
  data() {
    return {
      //
    }
  },
  computed: {
    ...mapGetters({
      displayLanguage: 'settings/getDisplayLanguage',
    }),
    flags() {
      return {
        ar_ma: '🇲🇦',
        fr: '🇫🇷',
        en_gb: '🇬🇧',
        pt: '🇵🇹',
        pl: '🇵🇱',
        es: '🇪🇸',
      }
    },
    staffMembersHandler() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const r = this.items
        .map(e => {
          // eslint-disable-next-line radix
          e.code = parseInt(`${e.code}`)
          return e
        })
        .filter(e => (this.filterActive ? e.quantity > 0 : true))

      if (this.sortBy === sortPerWeight) {
        return sort(r)
          .by([
            { desc: u => u.total_weight },
            { asc: u => u.code },
          ])
      }

      if (this.sortBy === sortPerQuantity) {
        return sort(r)
          .by([
            { desc: u => u.quantity },
            { asc: u => u.code },
          ])
      }

      if (this.sortBy === sortPerCode) {
        return sort(r)
          .by([
            { asc: u => u.code },
            { desc: u => u.quantity },
          ])
      }

      return r
    },
  },
  mounted() {
  },
  methods: {
    /**
     * @param {Number|String} v
     * @returns {*}
     */
    numberFormat(v) {
      return window.numberFormat(v)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../@core/scss/base/bootstrap-extended/_include';
@import '../../@core/scss/base/components/_include';

</style>
